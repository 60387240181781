<template>
	<div>
		<b-card>
			<b-row>
				<b-col cols="4">
					<label>Donor</label>

					<v-select
						v-model="donorName"
						class="flex-fill"
						clearable
						filterable
						searchable
						:options="getDonors"
						:reduce="(o) => o.label"
						placeholder="Select a donor"
					/>
				</b-col>
				<b-col cols="4">
					<label>Sector</label>
					<v-select
						v-model="sectorId"
						class="flex-fill"
						clearable
						filterable
						searchable
						:options="getSectors"
						:reduce="(o) => o.id"
						placeholder="Select a sector"
					/>
				</b-col>
				<b-col cols="4">
					<label>City</label>
					<v-select
						v-model="cityName"
						class="flex-fill"
						clearable
						filterable
						searchable
						:options="getCities"
						:reduce="(o) => o.label"
						placeholder="Select a city"
					/>
				</b-col>
			</b-row>

			<b-row class="mt-2">
				<b-col cols="4">
					<label for="from-date-datepicker">from a date</label>
					<b-form-datepicker id="from-date-datepicker" v-model="fromDate" />
				</b-col>

				<b-col cols="4">
					<label for="to-date-datepicker">to a date</label>
					<b-form-datepicker id="to-date-datepicker" v-model="toDate" />
				</b-col>

				<b-col cols="4" class="d-flex justify-content-end align-items-end">
					<b-button variant="primary" :disabled="!disable" @click="fetchSubmissions">Apply</b-button>
				</b-col>
			</b-row>
		</b-card>

		<base-table excel-file-name="excel-file" :headers="tableHeaders" :items="submissions" table-top paginate>
			<template #cell(created_at)="{ item }">
				<span>{{ new Date(item.created_at).toLocaleDateString() }}</span>
			</template>
		</base-table>
	</div>
</template>

<script>
import vSelect from 'vue-select';
import { BButton, BCard, BFormDatepicker, BRow, BCol } from 'bootstrap-vue';
import BaseTable from '@/components/BaseTable.vue';
import api from '@/utils/api';
import { mapGetters } from 'vuex';

export default {
	name: 'SafeguardTableWithFilter',

	components: {
		vSelect,
		BaseTable,
		BButton,
		BCard,
		BFormDatepicker,
		BRow,
		BCol,
	},

	props: {
		reportLink: { type: String, required: true },

		tableHeaders: { type: Array, required: true },
	},

	data: () => ({
		submissions: [],
		cityName: '',
		donorName: '',
		sectorId: '',
		fromDate: '',
		toDate: '',
	}),

	computed: {
		disable() {
			return !!this.donorName;
		},
		...mapGetters('attributes', ['getCities', 'getDonors', 'getSectors']),
	},

	methods: {
		async fetchSubmissions() {
			const params = new URLSearchParams({
				donor: this.donorName || '',
				sector: this.sectorId || '',
				city: this.cityName || '',
				from_date: this.fromDate || '',
				to_date: this.toDate || '',
			});

			const { data } = await api.call({
				path: `${this.reportLink}?${params}`,
				method: 'GET',
			});

			this.submissions = data;
		},
	},
};
</script>

<style lang="scss" scoped>
.form-filter {
	gap: 1rem;
}
</style>
