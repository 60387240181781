<template>
	<safeguard-table-with-filter
		report-link="reports/esmps-indicators"
		:table-headers="[
			{ key: 'project_id' },
			{ key: 'esmp' },
			{ key: 'supply_the_ppes_for_approval' },
			{ key: 'contract_with_hospital_for_emergency' },
			{ key: 'prepare_emergency_plan_in_the_site' },
			{ key: 'build_room_and_toilet_in_the_site' },
			{ key: 'conduct_risk_assessment_by_the_supervisor' },
			{ key: 'fence_the_site' },
			{
				key: 'raise_awareness_of_community',
				label: 'Raise awareness of community for OHS, GBV&SEA & GRM and conduct Social agreement',
			},
			{ key: 'training_of_workers', label: 'Training of workers for OHS & GRM and signature by them.' },
			{ key: 'contract_with_ohs', label: 'Contract with OHS technician by the contractor under supervisor (on site)' },
			{ key: 'contract_with_guard', label: 'Contract with 24- hour guard in the site (on site).' },
			{ key: 'set_grm_tools', label: 'Set GRM tools (project board & compliance box)' },
			{ key: 'workers_code_of_conduct' },
			{ key: 'contractor_code_of_conduct' },
			{
				key: 'contractor_between_the_contractor_and_aid_office',
				label: 'Contract between the contractor and the First aid officer (on site)',
			},
			{ key: 'hard_copy_file_in_site_for_accidents_form' },
			{ key: 'hard_copy_file_in_site_for_permit_to_work_forms' },
			{ key: 'photos_of_safety_kit', label: 'Photos of (covid-19) safety kit' },
			{ key: 'provide_emergency_car_in_site', label: 'Provide on-site Emergency car in site' },
			{ key: 'provide_stickers_that_shows_instructions', label: 'Provide stickers that shows instructions of OHS and (COVID-19)' },
			{
				key: 'have_technicians_been_assigned_to_the_site',
				label: 'Have technicians been assigned to the site under the consultant ?',
			},
			{ key: 'have_you_been_sketched_for_the_site', label: 'Have you been sketched for the site?' },
			{ key: 'is_an_emergency_instruction', label: 'Is an emergency instruction board prepared on site?' },
			{
				key: 'has_metal_plate_been_provided',
				label: 'Has a metal plate been provided for the project, indicating the complaint numbers?',
			},
		]"
	/>
</template>

<script>
import SafeguardTableWithFilter from '../components/SafeguardTableWithFilter.vue';

export default {
	name: 'LocationVisits',
	components: { SafeguardTableWithFilter },
};
</script>
