import { render, staticRenderFns } from "./SafeguardTableWithFilter.vue?vue&type=template&id=7a5d2a65&scoped=true&"
import script from "./SafeguardTableWithFilter.vue?vue&type=script&lang=js&"
export * from "./SafeguardTableWithFilter.vue?vue&type=script&lang=js&"
import style0 from "./SafeguardTableWithFilter.vue?vue&type=style&index=0&id=7a5d2a65&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7a5d2a65",
  null
  
)

export default component.exports